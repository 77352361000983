import React, { useState, useEffect, useCallback } from 'react';
import { FluentProvider, teamsLightTheme, Card, Button, Spinner, Dialog, DialogTrigger, DialogSurface, DialogBody, DialogTitle, DialogContent, DialogActions, Text, TabList, Tab } from '@fluentui/react-components';
import { MsalProvider, useMsal, useIsAuthenticated, useMsalAuthentication } from "@azure/msal-react";
import { PublicClientApplication, InteractionRequiredAuthError, InteractionType, InteractionStatus, EventType, AccountInfo } from "@azure/msal-browser";
import BusinessTable from './BusinessTable';

// MSAL configuration
const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_MSAL_CLIENT_ID!,
    authority: process.env.REACT_APP_MSAL_AUTHORITY!,
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

const msalInstance = new PublicClientApplication(msalConfig);

// Environment-specific URL
const getOrchestratorUrl = (id: string, pageToken?: string): string => {
  const baseUrl = process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_GET_ORCHESTRATOR_URL_PROD
    : process.env.REACT_APP_GET_ORCHESTRATOR_URL_LOCAL;

  let url = `${baseUrl}?id=${id}`;

  if (process.env.REACT_APP_ENVIRONMENT === 'production') {
    url += `&code=${process.env.REACT_APP_GET_ORCHESTRATOR_CODE}`;
  }

  if (pageToken) {
    url += `&pagetoken=${pageToken}`;
  }

  return url;
};

// LoadingSpinner component
const LoadingSpinner: React.FC<{ message: string }> = ({ message }) => (
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flexDirection: 'column' }}>
    <Spinner size="large" />
    <div style={{ marginTop: '20px', fontSize: '18px' }}>{message}</div>
  </div>
);

interface Business {
  name: string;
  address: string;
  phone_number?: string;
  website?: string;
  types?: string[];
  scope: string;
  inFoxy: boolean;
  place_id: string;
}

interface ApiResponse {
  combinedPlaces: Business[];
  nextPageToken?: string;
  buildingId: string;
  foxy_fulladdress?: string;
  foxyAI?: string[];
}

// Updated GetOpenRouter function
const getOpenRouterUrl = (): string => {
  const baseUrl = process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_OPENROUTER_URL_PROD
    : process.env.REACT_APP_OPENROUTER_URL_LOCAL;

  let url = `${baseUrl}`;

  if (process.env.REACT_APP_ENVIRONMENT === 'production') {
    url += `?code=${process.env.REACT_APP_OPENROUTER_CODE}`;
  }

  return url;
};

async function GetOpenRouter(userPrompt: string, model: string, systemPrompt: string): Promise<string> {
  try {
    console.log('Calling GetOpenRouter with params:', { userPrompt, model, systemPrompt });
    const url = getOpenRouterUrl();
    console.log('GetOpenRouter URL:', url);
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user_prompt: userPrompt,
        model: model,
        system_prompt: systemPrompt
      }),
    });

    console.log('GetOpenRouter raw response:', response);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.text();
    console.log('GetOpenRouter response data:', data);
    return data;
  } catch (error) {
    console.error('Error in GetOpenRouter:', error);
    throw error;
  }
}

function App() {
  const [data, setData] = useState<ApiResponse | null>(null);
  const [rawData, setRawData] = useState<string>('');
  const [selectedBusiness, setSelectedBusiness] = useState<Business | null>(null);
  const [nextPageToken, setNextPageToken] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [loadingMessage, setLoadingMessage] = useState<string>("Initializing...");
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [dialogMessage, setDialogMessage] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [buildingId, setBuildingId] = useState<string | null>(null);
  const [selectedTab, setSelectedTab] = useState<string>("foxy-ai");
  const [foxyAIPlaces, setFoxyAIPlaces] = useState<Business[]>([]);
  const [openRouterResponse, setOpenRouterResponse] = useState<string | null>(null);

  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get('id');

  useMsalAuthentication(InteractionType.Redirect);

  const fetchBusinessesCallback = useCallback(async (id: string, pageToken: string | null = null) => {
    console.log('fetchBusinessesCallback called with id:', id);
    if (!id) {
      console.log('No ID provided, cannot fetch businesses');
      setIsLoading(false);
      setError("No ID provided. Please include an ID in the URL parameters.");
      return;
    }

    setIsLoading(true);
    setLoadingMessage("One moment, Foxy AI is scrubbing this Building...");
    setError(null);

    try {
      if (accounts.length === 0) {
        throw new Error("No accounts available");
      }

      if (!instance.getActiveAccount()) {
        instance.setActiveAccount(accounts[0]);
      }

      const accessTokenRequest = {
        scopes: ["user.read"],
        account: instance.getActiveAccount() as AccountInfo
      };

      const tokenResponse = await instance.acquireTokenSilent(accessTokenRequest);
      const token = tokenResponse.accessToken;

      const url = getOrchestratorUrl(id, pageToken || undefined);
      console.log('Fetching data from URL:', url);
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        mode: 'cors',
        credentials: 'include'
      });

      if (!response.ok) {
        const responseText = await response.text();
        console.error('API Response:', responseText);
        throw new Error(`HTTP error! status: ${response.status}, response: ${responseText}`);
      }

      const contentType = response.headers.get("content-type");
      if (!contentType || !contentType.includes("application/json")) {
        const responseText = await response.text();
        console.error('Unexpected content type:', contentType);
        console.error('Response content:', responseText);
        throw new Error(`Unexpected content type: ${contentType}`);
      }

      const newData: ApiResponse = await response.json();
      console.log('Fetched data:', newData);

      setRawData(JSON.stringify(newData, null, 2));

      if (pageToken) {
        setData((prevData: ApiResponse | null) => prevData ? {
          ...prevData,
          combinedPlaces: [...prevData.combinedPlaces, ...newData.combinedPlaces]
        } : newData);
      } else {
        setData(newData);
        setBuildingId(newData.buildingId);
      }

      setNextPageToken(newData.nextPageToken || null);

      if (newData.foxyAI && Array.isArray(newData.foxyAI)) {
        const placesToRemove = new Set(newData.foxyAI);
        const filteredPlaces = newData.combinedPlaces.filter((place: Business) => 
          place.place_id && !placesToRemove.has(place.place_id)
        );
        setFoxyAIPlaces(filteredPlaces);
      } else {
        console.error('Invalid or missing foxyAI data:', newData.foxyAI);
        setFoxyAIPlaces([]);
      }

      if (newData.foxy_fulladdress) {
        console.log('Calling GetOpenRouter with foxy_fulladdress:', newData.foxy_fulladdress);
        try {
          const openRouterResult = await GetOpenRouter(
            newData.foxy_fulladdress,
            "google/gemini-pro-1.5",
            "The user is going to present you with an address in Canada. This is being used to help prospect for new business. This address has already been used in Google and Azure nearby places where those companies are being displayed to the user. Your task is to respond in 4 sentences at most, telling the user anything useful about this building that you can find. For example, if it's a high rise, area of the city its in, multi or single tenant, nearby places of interest, really anything that might help a sales team. Don't respond with anything else, just the direct info about the address."
          );
          console.log('GetOpenRouter result:', openRouterResult);
          setOpenRouterResponse(openRouterResult);
        } catch (error) {
          console.error('Error fetching OpenRouter response:', error);
          setOpenRouterResponse('Error fetching building information.');
        }
      } else {
        console.log('foxy_fulladdress not found in newData');
      }

    } catch (error) {
      console.error('Error fetching data:', error);
      if (error instanceof InteractionRequiredAuthError) {
        instance.acquireTokenRedirect({
          scopes: ["user.read"],
          account: instance.getActiveAccount() as AccountInfo
        });
      } else if (error instanceof Error && error.message === "No accounts available") {
        console.log("No accounts available, redirecting to login");
        instance.loginRedirect();
      } else {
        setError(`Error fetching data: ${error instanceof Error ? error.message : 'Unknown error'}`);
      }
    } finally {
      setIsLoading(false);
    }
  }, [instance, accounts]);

  useEffect(() => {
    const callbackId = instance.addEventCallback((event) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && id) {
        console.log("Login successful, fetching businesses");
        fetchBusinessesCallback(id);
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [instance, id, fetchBusinessesCallback]);

  useEffect(() => {
    if (isAuthenticated && inProgress === InteractionStatus.None && id) {
      console.log('Authenticated, fetching businesses');
      fetchBusinessesCallback(id);
    }
  }, [isAuthenticated, inProgress, id, fetchBusinessesCallback]);

  useEffect(() => {
    if (data && data.foxy_fulladdress) {
      document.title = `Infusion Search - ${data.foxy_fulladdress}`;
    } else {
      document.title = 'Infusion Search';
    }
  }, [data]);

  const handleSelect = (business: Business) => {
    setSelectedBusiness(business);
  };

  const createAccount = async (accountName: string, buildingId: string) => {
    try {
      const graphTokenRequest = {
        scopes: ["user.read"],
        account: instance.getActiveAccount() as AccountInfo
      };

      const dynamicsTokenRequest = {
        scopes: ["https://foxy.crm3.dynamics.com/.default"],
        account: instance.getActiveAccount() as AccountInfo
      };

      console.log('Requesting tokens with scopes:', 
        'Graph:', graphTokenRequest.scopes, 
        'Dynamics:', dynamicsTokenRequest.scopes
      );

      const [graphTokenResponse, dynamicsTokenResponse] = await Promise.all([
        instance.acquireTokenSilent(graphTokenRequest),
        instance.acquireTokenSilent(dynamicsTokenRequest)
      ]);

      const graphToken = graphTokenResponse.accessToken;
      const dynamicsToken = dynamicsTokenResponse.accessToken;

      console.log('Tokens acquired successfully.');
      console.log('Graph token length:', graphToken.length);
      console.log('Dynamics token length:', dynamicsToken.length);

      let createAccountUrl = process.env.REACT_APP_CREATE_ACCOUNT_URL;
      if (!createAccountUrl) {
        throw new Error('REACT_APP_CREATE_ACCOUNT_URL is not defined');
      }

      if (process.env.REACT_APP_ENVIRONMENT === 'production') {
        createAccountUrl += `?code=${process.env.REACT_APP_GET_ORCHESTRATOR_CODE}`;
      }

      console.log('Creating account with URL:', createAccountUrl);

      const headers: HeadersInit = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${dynamicsToken}`
      };

      if (process.env.REACT_APP_USE_GRAPH_TOKEN === 'true') {
        headers['X-MS-Token-AAD-Access-Token'] = graphToken;
      }

      console.log('Request headers:', Object.keys(headers));
      console.log('Request body:', JSON.stringify({ accountName, buildingId }));

      const response = await fetch(createAccountUrl, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({ accountName, buildingId }),
        mode: 'cors',
        credentials: 'include'
      });

      console.log('CreateAccount response status:', response.status);
      console.log('CreateAccount response headers:', JSON.stringify(Array.from(response.headers.entries())));

      if (!response.ok) {
        const responseText = await response.text();
        console.error('API Response:', responseText);
        throw new Error(`HTTP error! status: ${response.status}, response: ${responseText}`);
      }

      const contentType = response.headers.get("content-type");
      if (!contentType || !contentType.includes("application/json")) {
        const responseText = await response.text();
        console.error('Unexpected content type:', contentType);
        console.error('Response content:', responseText);
        throw new Error(`Unexpected content type: ${contentType}`);
      }

      const result = await response.json();
      console.log('CreateAccount result:', result);
      return result;
    } catch (error) {
      console.error('Error creating account:', error);
      if (error instanceof InteractionRequiredAuthError) {
        console.log('Interaction required. Redirecting to login.');
        instance.acquireTokenRedirect({
          scopes: ["user.read", "https://foxy.crm3.dynamics.com/.default"],
          account: instance.getActiveAccount() as AccountInfo
        });
      }
      throw error;
    }
  };

  const handleSubmit = async () => {
    if (selectedBusiness && buildingId) {
      setIsLoading(true);
      setLoadingMessage("Creating account...");
      try {
        console.log('Submitting account creation for:', selectedBusiness.name);
        const result = await createAccount(selectedBusiness.name, buildingId);
        setDialogMessage(`Account created successfully. Account ID: ${result.accountId}`);
      } catch (error) {
        console.error('Error in handleSubmit:', error);
        setDialogMessage(`Error creating account: ${error instanceof Error ? error.message : 'Unknown error'}`);
      } finally {
        setIsLoading(false);
        setDialogOpen(true);
      }
    } else {
      setDialogMessage(selectedBusiness ? 'Building ID is missing' : 'Please select a business');
      setDialogOpen(true);
    }
  };

  const handleLoadMore = () => {
    if (nextPageToken && id) {
      fetchBusinessesCallback(id, nextPageToken);
    }
  };

  if (isLoading || inProgress !== InteractionStatus.None) {
    return <LoadingSpinner message={loadingMessage} />;
  }

  if (!isAuthenticated) {
    return <LoadingSpinner message="Not authenticated. Please log in." />;
  }

  if (!id) {
    return <div>No ID provided in the URL. Please include an ID in the URL parameters.</div>;
  }

  return (
    <FluentProvider theme={teamsLightTheme}>
      <div style={{ padding: '20px' }}>
        <Card>
          <h1>
            {data && data.foxy_fulladdress 
              ? data.foxy_fulladdress 
              : 'Building Information'}
          </h1>
          {error && (
            <Text style={{ color: 'red', marginBottom: '1rem' }}>{error}</Text>
          )}
          {openRouterResponse && (
            <Text style={{ marginBottom: '1rem', fontSize: '14px', color: '#666' }}>
              {openRouterResponse}
            </Text>
          )}
          <TabList selectedValue={selectedTab} onTabSelect={(_, data) => setSelectedTab(data.value as string)}>
            <Tab value="foxy-ai">Foxy AI</Tab>
            <Tab value="all-results">All Results</Tab>
            <Tab value="raw-data">Raw Data</Tab>
          </TabList>
          {selectedTab === "foxy-ai" && foxyAIPlaces.length > 0 && (
            <BusinessTable 
              businesses={foxyAIPlaces} 
              onSelect={handleSelect} 
              onSubmit={handleSubmit}
            />
          )}
          {selectedTab === "foxy-ai" && foxyAIPlaces.length === 0 && (
            <Text>No results from Foxy AI.</Text>
          )}
          {selectedTab === "all-results" && data && data.combinedPlaces && data.combinedPlaces.length > 0 && (
            <>
              <BusinessTable 
                businesses={data.combinedPlaces} 
                onSelect={handleSelect} 
                onSubmit={handleSubmit}
              />
              {nextPageToken && (
                <Button 
                  onClick={handleLoadMore} 
                  disabled={isLoading}
                  appearance="primary"
                  style={{ marginTop: '1rem' }}
                >
                  {isLoading ? 'Loading...' : 'Load More'}
                </Button>
              )}
            </>
          )}
          {selectedTab === "raw-data" && (
            <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
              {rawData}
            </pre>
          )}
        </Card>
      </div>
      <Dialog open={dialogOpen} onOpenChange={(event, data) => setDialogOpen(data.open)}>
        <DialogSurface>
          <DialogBody>
            <DialogTitle>Notification</DialogTitle>
            <DialogContent>
              {dialogMessage}
            </DialogContent>
            <DialogActions>
              <DialogTrigger disableButtonEnhancement>
                <Button appearance="secondary">Close</Button>
              </DialogTrigger>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    </FluentProvider>
  );
}

function AppWithMsal() {
  return (
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
  );
}

export default AppWithMsal;
