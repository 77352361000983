import React, { useState } from 'react';
import { Table, TableHeader, TableRow, TableHeaderCell, TableBody, TableCell, Radio, Link, Button, Image } from '@fluentui/react-components';

// Import logos
import googleMapsLogo from './assets/logos/googlemaps.png';
import azureMapsLogo from './assets/logos/azuremaps.png';
import foxyLogo from './assets/logos/foxyfav.png';

// Define the Business interface if it's not imported from elsewhere
interface Business {
  name: string;
  address: string;
  phone_number?: string;
  website?: string;
  types?: string[];
  scope: string;
  inFoxy: boolean;
  place_id: string;
}

interface BusinessTableProps {
  businesses: Business[];
  onSelect: (business: Business) => void;
  onSubmit: () => void;
}

function BusinessTable({ businesses, onSelect, onSubmit }: BusinessTableProps) {
  const [expandedRows, setExpandedRows] = useState<Record<number, boolean>>({});

  const handleSelect = (business: Business) => {
    onSelect(business);
  };

  const toggleRowExpansion = (index: number) => {
    setExpandedRows(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  const renderSourceLogo = (scope: string, inFoxy: boolean) => {
    const logoStyle = { width: '24px', height: '24px', marginRight: '4px' };
    
    if (inFoxy) {
      return <Image src={foxyLogo} alt="Foxy" style={logoStyle} />;
    }

    switch (scope) {
      case 'GOOGLE':
        return <Image src={googleMapsLogo} alt="Google Maps" style={logoStyle} />;
      case 'AZURE':
        return <Image src={azureMapsLogo} alt="Azure Maps" style={logoStyle} />;
      case 'GOOGLE AZURE':
        return (
          <>
            <Image src={googleMapsLogo} alt="Google Maps" style={logoStyle} />
            <Image src={azureMapsLogo} alt="Azure Maps" style={logoStyle} />
          </>
        );
      default:
        return scope || 'N/A';
    }
  };

  const renderTypes = (types: string[] | undefined, index: number) => {
    if (!types || types.length === 0) return 'N/A';
    
    if (expandedRows[index]) {
      return (
        <div>
          {types.map((type, i) => (
            <div key={i}>{type}</div>
          ))}
          <Button size="small" onClick={() => toggleRowExpansion(index)}>Show less</Button>
        </div>
      );
    } else {
      return (
        <div>
          {types[0]}
          {types.length > 1 && (
            <>
              {' '} {/* Add a space here */}
              <Button size="small" onClick={() => toggleRowExpansion(index)}>
                +{types.length - 1} more
              </Button>
            </>
          )}
        </div>
      );
    }
  };

  return (
    <div style={{ overflowX: 'auto' }}> {/* Add horizontal scroll for small screens */}
      <Table>
        <TableHeader>
          <TableRow>
            <TableHeaderCell style={{ width: '60px' }}><strong>Select</strong></TableHeaderCell>
            <TableHeaderCell style={{ width: '25%', minWidth: '200px' }}><strong>Name</strong></TableHeaderCell>
            <TableHeaderCell style={{ width: '30%', minWidth: '250px' }}><strong>Address</strong></TableHeaderCell>
            <TableHeaderCell style={{ width: '15%', minWidth: '120px' }}><strong>Phone</strong></TableHeaderCell>
            <TableHeaderCell style={{ width: '10%', minWidth: '100px' }}><strong>Website</strong></TableHeaderCell>
            <TableHeaderCell style={{ width: '15%', minWidth: '150px' }}><strong>Types</strong></TableHeaderCell>
            <TableHeaderCell style={{ width: '5%', minWidth: '80px' }}><strong>Source</strong></TableHeaderCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {businesses.map((business, index) => (
            <TableRow 
              key={index} 
              style={business.inFoxy ? { opacity: 0.5, backgroundColor: '#f0f0f0' } : {}}
            >
              <TableCell style={{ width: '60px' }}>
                <Radio 
                  onChange={() => handleSelect(business)} 
                  disabled={business.inFoxy}
                />
              </TableCell>
              <TableCell style={{ width: '25%', minWidth: '200px' }}>{business.name}</TableCell>
              <TableCell style={{ width: '30%', minWidth: '250px' }}>{business.address}</TableCell>
              <TableCell style={{ width: '15%', minWidth: '120px' }}>{business.phone_number || 'N/A'}</TableCell>
              <TableCell style={{ width: '10%', minWidth: '100px' }}>
                {business.website ? (
                  <Link href={business.website} target="_blank" rel="noopener noreferrer">
                    Website <span style={{ fontSize: '0.8em', verticalAlign: 'super' }}>↗</span>
                  </Link>
                ) : 'N/A'}
              </TableCell>
              <TableCell style={{ width: '15%', minWidth: '150px' }}>{renderTypes(business.types, index)}</TableCell>
              <TableCell style={{ width: '5%', minWidth: '80px' }}>
                {renderSourceLogo(business.scope, business.inFoxy)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Button onClick={onSubmit} appearance="primary" style={{ marginTop: '1rem' }}>
        Submit Selection
      </Button>
    </div>
  );
}

export default BusinessTable;
